<messages>["./components/ClientCreateUpdate"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <base-layout mw1>
    <!-- "client does not exist" alert -->
    <v-col cols="12">
      <v-alert
        type="error"
        :value="!isLoading && !client">
        {{ $t ('invalidClientId', { id: $route.params.id }) }}
      </v-alert>
    </v-col>

    <v-col cols="12">
      <client-create-update
        v-if="client"
        :is-create="isClone"
        :client-data="client"
        @unsavedChanges="setUnsavedChanges"
        @success="onSuccess"
        @cancel="back"/>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapActions, mapMutations} from 'vuex'
  import ClientCreateUpdate from './components/ClientCreateUpdate'

  import unsavedChangesHelper from '@/app/core/mixins/UnsavedChangesHelper'

  import BaseLayout from '@/app/core/components/BaseLayout'

  export default {
    name: 'ClientEdit',

    components: {
      BaseLayout,
      ClientCreateUpdate
    },

    mixins: [unsavedChangesHelper],

    props: {
      id: {
        type: [Number, String],
        required: true
      },
      isClone: Boolean
    },

    data () {
      return {
        isLoading: true,
        client: undefined
      }
    },

    watch: {
      id (newValue) {
        this.loadClient (newValue)
      }
    },

    created () {
      this.loadClient (this.id)
    },

    methods: {
      ...mapMutations ('notification', ['addEvent']),
      ...mapActions ('request', [
        'fetchData'
      ]),

      loadClient (id) {
        this.isLoading = true

        this.fetchData ({
          op: 'client/load',
          params: {id},
          cb: data => {
            this.client = data.clientData
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      onSuccess (data) {
        this.addEvent ({
          type: 'success',
          message: this.$t ('saved.update', {link: data.name}),
          objects: [{
            name: data.name,
            link: {name: 'client.view', params: {id: data.id}}
          }]
        })
        this.back ()
      },

      back () {
        this.$router.back ()
      }
    }
  }
</script>
